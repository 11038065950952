<template>
  <div class="card-live">
    <div class="live-place">{{ place }}</div>
    <div class="live-desc">{{ desc }}</div>
    <div class="live-ctl" v-if="isAdmin">
      <button @click.prevent="onCopyLink" title="Копировать ссылку">
        <Share />
      </button>
      <button @click.prevent="onLink" title="Редактировать">
        <EditBroadcast />
      </button>
    </div>
  </div>
</template>

<script>
import Share from '@/pic/svg/share.vue'
import EditBroadcast from '@/pic/svg/edit-broadcast.vue'
import { APP_ROUTE } from '@/const'

export default {
  name: 'card-live',
  components: { EditBroadcast, Share },
  props: {
    id: { type: String },
    place: { type: String },
    desc: { type: String },
    isAdmin: { type: Boolean }
  },
  methods: {
    onCopyLink() {
      const link = `${window.location.origin}/${APP_ROUTE.VIDECAM}/${this.id}`
      navigator.clipboard.writeText(link).catch((err) => console.log(err))
    },
    onLink() {
      this.$router.push(`${APP_ROUTE.VIDECAM}/${this.id}`)
    }
  }
}
</script>

<style lang="scss">
@import 'styles/index';
</style>
