<template>
  <div class="live-panel">
    <h2>Офисные столовые<LinkAdm v-if="isAdmin" /></h2>
    <router-link
      v-for="live in allLive"
      :key="live.id"
      :to="`/${APP_ROUTE.LIVE}/${live.id}`"
      class="live-link"
    >
      <LiveCard :id="live.id" :place="live.place" :desc="live.description" :is-admin="isAdmin" />
    </router-link>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import LiveCard from '../../../../comp/live-card'
import LinkAdm from './comp/adm/adm.vue'
import { ACTION_AXIOS_LIVE, GETTER_APP, GETTER_LIVE } from '@/store/const/type'
import { APP_ROUTE } from '@/const'
export default {
  name: 'live-block',
  components: { LiveCard, LinkAdm },
  data() {
    return { APP_ROUTE }
  },
  methods: mapActions({ getAxiosLive: ACTION_AXIOS_LIVE.GET }),
  computed: { ...mapGetters({ allLive: GETTER_LIVE.ALL, isAdmin: GETTER_APP.IS_ADMIN }) },
  created() {
    this.getAxiosLive()
  }
}
</script>

<style lang="scss">
@import './styles/index.scss';
</style>
