<template>
  <button @click.prevent="onClick" title="Перейти в админ панель">
    <Settings />
  </button>
</template>

<script>
import Settings from '@/pic/svg/settings.vue'
import { APP_ROUTE } from '@/const'

export default {
  name: 'link-page-adm',
  components: { Settings },
  data() {
    return {}
  },
  methods: {
    onClick() {
      this.$router.push(`${APP_ROUTE.VIDECAM}/admin`)
    }
  },
  computed: {},
  created() {}
}
</script>

